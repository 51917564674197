.twigstrap-slider {
    &__item {
        > .card {
            height: 100%;
        }
    }

    &.is-initialized:not(.is-active),
    &:not(.is-active) {
        .twigstrap-slider__list {
            display: flex;
            --bs-gutter-x: 1.5rem;
            --bs-gutter-y: 0;
            margin-right: calc(-0.5*var(--bs-gutter-x))!important;
            margin-left: calc(-0.5*var(--bs-gutter-x))!important;

            .twigstrap-slider__item {
                flex: 0 0 auto;
                width: 100%;
                max-width: 100%;
                padding-right: calc(var(--bs-gutter-x)*.5);
                padding-left: calc(var(--bs-gutter-x)*.5);

                @include mq(sm) {
                    width: 50%;
                }
                @include mq(lg) {
                    width: 33.3333333333%;
                }
                @include mq(xxl) {
                    width: 25%;
                }
            }
        }
    }
}
